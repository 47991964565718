import React from "react";
import {graphql, Link} from "gatsby";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar";


const CodeIndex = ({data, location}) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`;
    const posts = data.allMarkdownRemark.nodes;


    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <Seo title="All posts"/>
                <p>
                    No music posts found.
                </p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="Code"/>
            <Sidebar quote={data.quote} music={data.music}/>

            <ol className="post-grid">
                {posts.map(post => {
                    const title = post.frontmatter.title || post.frontmatter.slug;
                    return (
                        <li key={post.frontmatter.slug}>
                            <article
                                className="post-list-item content"
                                itemScope
                                itemType="http://schema.org/Article"
                            >
                                <header>
                                    <h2>
                                        <Link to={post.frontmatter.slug} itemProp="url">
                                            <span itemProp="headline">{title}</span>
                                        </Link>
                                    </h2>
                                </header>
                                <section>
                                </section>
                            </article>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
};

export default CodeIndex;

export const pageQuery = graphql`
  query allCode(
    $quoteId: String
    $musicId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {contentType: {eq: "code"}}}) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
      }
    }
    quote: markdownRemark(id: { eq: $quoteId }) {
      id
      html
      frontmatter {
        title
        author
        source
        source_link
      }
    }
    music: markdownRemark(id: { eq: $musicId }) {
        id
        frontmatter {
          title
          slug
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        html
    }
  }
`;
